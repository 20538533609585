import PropTypes from "prop-types"
import { Link } from "gatsby"
import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <nav className="py-2 gPadding bg-white fixed top-0 w-full z-50 bg-opacity-90">
      <div className="flex flex-wrap items-center justify-between max-w-7xl mx-auto">
        <Link to="/" className="flex items-center mr-6 text-black">
          <StaticImage 
            src="../images/lawn-chair-logo.png"
            alt="Lawn Chair"
            placeholder="blurred"
            className="logo"
          />
        </Link>
        <div className="block lg:hidden">
          <button
            onClick={() => toggleExpansion(!isExpanded)}
            className="flex items-center px-3 py-2"
          >
            <svg
              className="w-6 h-6 fill-black"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } w-full block lg:flex lg:items-center lg:w-auto`}
        >
          
          <div className="text-lg tracking-widest uppercase text-right mr-3 lg:mr-0">
            <a
              href="/#wine"
              onClick={() => toggleExpansion(!isExpanded)}
              className="block mt-4 lg:inline-block lg:mt-0 text-black hover:text-lawn-orange"
            >
              Wine
            </a>
            <a
              href="#contact"
              onClick={() => toggleExpansion(!isExpanded)}
              className="block mt-4 lg:ml-10 lg:inline-block lg:mt-0 text-black hover:text-lawn-orange"
            >
              Contact
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
