import React from "react"
import { Link } from "gatsby"

function Footer() {

  return (
    <footer name="contact" id="contact" className="relative pt-20 md:pt-36 pb-0 z-30">
   
      <div className="gPadding w-full relative max-w-1440 mx-auto text-white">

        <div className="relative">
          
          <h2 className="uppercase mb-4">Contact</h2>

          <a href="mailto:sales@lawnchairwine.com" className="text-2xl py-2 hover:text-lawn-orange border-white table"> sales@lawnchairwine.com</a>
          <a href="mailto:info@lawnchairwine.com" className="text-2xl py-2 hover:text-lawn-orange border-white table"> info@lawnchairwine.com</a>
          
          <div className="mt-16 md:mt-20 mb-8">
            <Link to="/privacy-policy/" className="text-2xl py-2 hover:text-lawn-orange border-white inline-block mr-10"> Privacy policy</Link>
            <Link to="/terms/" className="text-2xl py-2 hover:text-lawn-orange border-white inline-block"> Terms of Use</Link>
          </div>

          <div className="flex flex-wrap items-end justify-between ">
            <div className="text-2xl">© {new Date().getFullYear()} Lawn Chair Inc.</div>
            <h2 className="inline-block relative mt-10 md:mt-0 text-5xl md:text-7xl">Lawn <span className="-ml-2">Chair</span><sup>TM</sup></h2>
          </div>

          
        </div>

      </div>

      <div className="diagonal-reverse bg-black"></div>

    <div className="footer-filler" ></div>
    </footer>
  )
}



export default Footer
